@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root {
  font-family: "Outfit", serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 10px;
  border: 2px solid #ffffff;
}
